import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
const { loadAddressBookSelections } = iceStarkData.store.get('commonAction')?.addressBooks;
const { getRoleSelections } = iceStarkData.store.get('commonAction')?.role;
const { getUserData, createUser, editUser, userInvitationCodes, binding } = iceStarkData.store.get('commonAction')?.users;
import { reduxForm } from 'redux-form';
import { Button, Input, FormGroupList, FormGroup,Tip } from 'tyb';
import { NewSelect } from 'ucode-components';
import { trims } from 'ucode-utils';
import { MultipleSelect } from 'ucode-components';
import { FormGroupField } from 'tyb';
import './SubAccountForm.scss';
import JSEncrypt from '@/libs/encrypt.js';

const blankReg = /^\s+$/;
const termReg = /^[a-zA-Z\u4e00-\u9fa5]+$/;

@connect(
    (state) => {
        return {
            form: state.form,
            openIds: state.users.openIds,
            addressBookSelections: state.addressBooks.addressBookSelections,
            roleSelections: state.role.roleSelections,
            backend: state.backend,
        }
    },
    {
        getUserData: getUserData.REQUEST,
        createUser: createUser.REQUEST,
        editUser: editUser.REQUEST,
        loadAddressBookSelections: loadAddressBookSelections.REQUEST,
        getRoleSelections: getRoleSelections.REQUEST,
        userInvitationCodes: userInvitationCodes.REQUEST,

        binding: binding.REQUEST,
    }
)


class UserCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModify: false
        }
    }

    componentDidMount() {
        const { openIds, getUserData, loadAddressBookSelections, getRoleSelections, location, initialize } = this.props;
        const parsed = queryString.parse(location.search);
        getRoleSelections(openIds.userType);
        if (!this.props.backend.system && this.props.backend.open_id && parsed.openId) {
            this.props.binding({
                data: { openId: parsed.openId },
                callback: (res2) => {

                    this.setState({ isModify: true })
                    getUserData({
                        id: res2.id, callback: (res) => {
                            // console.log(res, 'res------');
                            const { roleIds, password = '' } = res;
                            let roleIdsArr = [];
                            roleIds.forEach(val => {
                                roleIdsArr.push(val.toString())
                            })
                            let obj = {};
                            if (this.props.openIds.userType == 'SYSTEM' || this.props.openIds.type == 'TY') {
                                obj.password = '**********';
                            }
                            initialize({ ...res, showAddressBookId: res.addressBookId, roleIds: roleIdsArr, ...obj });
                        }
                    })
                }
            })
        }
        else if (parsed.id) {
            this.setState({ isModify: true })
            getUserData({
                id: parsed.id, callback: (res) => {
                    // console.log(res, 'res------');
                    const { roleIds, password = '' } = res;
                    let roleIdsArr = [];
                    roleIds.forEach(val => {
                        roleIdsArr.push(val.toString())
                    })
                    let obj = {};
                    if (this.props.openIds.userType == 'SYSTEM' || this.props.openIds.type == 'TY') {
                        obj.password = '**********';
                    }
                    initialize({ ...res, showAddressBookId: res.addressBookId, roleIds: roleIdsArr, ...obj });
                }
            })
        } else {
            loadAddressBookSelections({ status: 'ENABLE' });
            if (this.props.openIds.userType != 'SYSTEM' && this.props.openIds.userType != 'TY') {
                this._getUserInviteCode();
            }
        }
    }

    _getUserInviteCode() {
        const { userInvitationCodes, initialize, form: { userForm = {} } } = this.props;
        userInvitationCodes({
            callback: (res) => {
                initialize({ ...userForm.values, invitationCode: res.invitationCode || '' })
            }
        });
    }

    validateInputUser = (value) => {
        return value && value !== '请选择人员' ? undefined : '请选择人员'
    }

    validateSelectedUser = (value) => {
        return value && value.length > 0 ? undefined : '请选择角色权限'
    }

    validateAccount = (value) => {
        if (!value || blankReg.test(value)) {
            return '请输入账号';
        } else if (value.trim().length > 30) {
            return '长度不能大于30个字符';
        } else {
            return null
        }
    }

    validatePassword = (value) => {
        if (!value || blankReg.test(value)) {
            return '请输入密码';
        } else if(!(/^(?!^.*[\u4E00-\u9FA5].*$)(?=.*[a-zA-Z].*)(?=.*[0-9].*)(?=.*((?=[\x21-\x7e]+)[^A-Za-z0-9]).*)\S{9,30}$/).test(value.trim())){
            return '密码为包含数字、大小写字母、特殊字符任意三种组合，长度大于8，小于31的字符';
        } else if (value.length > 30) {
            return '长度不能大于30个字符';
        } else {
            return null
        }
    }

    handleSubmit = (data) => {
        const { createUser, editUser, history } = this.props;
        let newdata = { ...data };

        var encrypt = new JSEncrypt();
        encrypt.setPublicKey(`MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQqJ4SyujGCKwQvNzsKVMcoSQnoE60zKSPoUen7c+FRqJqineEFNhk7Hc8qWfZDbzMtIAMeg78+SgiOJ1es+KOIUOncaN0dae34QP5AdmORAm1AUXG8osmCe54SU0cnnIwS6L6RE6OIiFZzf//Eo3blf1x3sBLc6WZ/KDSqRLKCwIDAQAB`);
        
        console.log(newdata)
        if (newdata.password == "**********") {
            newdata.password = '';
        }
        if (newdata.id) {
            newdata.addressBookId = newdata.showAddressBookId

        }
        else {
            newdata.addressBookId = newdata.addressBookId.value;
        }
        let newdata1 = {...newdata};
        newdata1.password = encrypt.encrypt(newdata1.password);
       
        newdata.id ? editUser({ data: trims(newdata1), history }) : createUser({ data: trims(newdata1), history })
    }

    goBack = () => {
        this.props.history.goBack();
    }

    render() {
        const { handleSubmit, openIds, addressBookSelections = [], roleSelections, form: { userForm = {} }, location } = this.props;
        const { isModify } = this.state;
        const parsed = queryString.parse(location.search);
        return (
            <div>
                {(!this.props.backend.system && this.props.backend.open_id && parsed.openId) &&   <Tip style={{ marginTop: '20px' }} hasClose={false} type="success">为确保平台部分功能正常使用，请在通讯录模块中完善用户手机号、邮箱</Tip>} 
            <div className="user-form-bg">
   
                <FormGroupList>
                    {!isModify && <FormGroupField
                        name="addressBookId"
                        label="选择通讯录"
                        placeholder="选择人员"
                        style={{ width: 330 }}
                        required
                        component={NewSelect}
                        needText
                        list={addressBookSelections}
                        validate={this.validateInputUser}
                    // format={null}
                    />}
                    {isModify && <FormGroup
                        label="选择通讯录"
                        required
                        control={Input}
                        disabled
                        value={userForm.values.addressBookName || ''}
                    />}
                    <FormGroupField
                        name="roleIds"
                        label="角色权限"
                        placeholder="选择角色"
                        style={{ width: 330 }}
                        required
                        component={MultipleSelect}
                        list={roleSelections}
                        validate={this.validateSelectedUser}
                        format={null}
                    />
                    {/* {(!(!this.props.backend.system && this.props.backend.open_id && parsed.openId)) && (openIds.userType != 'SYSTEM' && openIds.type != 'TY') && <FormGroupField
                        name="invitationCode"
                        label="邀请码"
                        required
                        component={Input}
                        disabled
                    >
                        <span className="modify-poolname-btn" onClick={() => { this._getUserInviteCode() }}>刷新</span>
                    </FormGroupField>} */}
                    {(!(!this.props.backend.system && this.props.backend.open_id && parsed.openId)) && (openIds.userType == 'SYSTEM' || openIds.type == 'TY') && <React.Fragment>
                        <FormGroupField
                            name="emailAccount"
                            label="登录账号"
                            autoComplete="off"
                            required
                            component={Input}
                            validate={this.validateAccount}
                            onFocus={e => {
                                // if (e.target.value == ' ') {
                                //     this.props.initialize({ ...userForm.values, emailAccount: '' });
                                // }
                            }}
                        />
                        <FormGroupField
                            name="password"
                            label="登录密码"
                            autoComplete="new-password"
                            type="password"
                            required
                            component={Input}
                            validate={this.validatePassword}
                        />
                    </React.Fragment>}
                </FormGroupList>
                <div className="form-edit-border">
                    <Button
                        onClick={handleSubmit(this.handleSubmit)}
                    >{isModify ? '确认' : '新增'}</Button>
                    <Button
                        className="tc-15-btn weak"
                        onClick={this.goBack}
                    >取消</Button>
                </div>
            </div>
            </div>
        )
    }

}

const userEditForm = reduxForm({
    form: 'userForm',
    initialValues: { emailAccount: '' }
    // initialValues: { emailAccount: ' ' }
})(UserCreate);

export default userEditForm;